/* styles.css */
/* Hide arrows in Webkit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

:root {
  --kendo-table-border-width: 1px;
  --kendo-table-cell-vertical-border-width: 1px;
  --kendo-table-cell-horizontal-border-width: 1px;
}

/* Apply these variables to your table or specific table elements */
.custom-table {
  border-collapse: collapse;
  border-spacing: 0;
}

.custom-table th,
.custom-table td {
  border: var(--kendo-table-border-width) solid #8f8f8f; /* Adjust color as needed */
}

.custom-table-2 {
  background-color: #f8f8f8;
}

.custom-table-2 th,
.custom-table-2 td {
  background-color: #f8f8f8;
}

body {
  background-color: #f8f8f8;
}
